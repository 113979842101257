import React from 'react';
import PropTypes from 'prop-types';
import Image from 'reusecore/src/elements/Image'
import Box from 'reusecore/src/elements/Box';

import {
  ImageListWrapper,
} from './imagelist.style';

const ImageList = ({
  className,
  images = [],
}) => {
  // Add all class to an array
  const addAllClasses = ['reusecore__imagelist'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <ImageListWrapper
      className={addAllClasses.join(' ')}
    >
      {images.map(({
        src,
        width,
        href
      }) => {
        return (
          <Box width={width} mr={[1,2,3,4]}>
            <a href={href} target="_blank" rel="noopener noreferrer"><Image src={src} /></a>
          </Box>
        )
      })}
    </ImageListWrapper>
  );
};


ImageList.propTypes = {
  /** ClassName of the AccordionBody. Default class is accordion__body */
  className: PropTypes.string,

  /** Used to render any component */
  children: PropTypes.element,
};

export default ImageList
