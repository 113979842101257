import styled from 'styled-components';

const ImageListWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
`

export {
  ImageListWrapper
}
